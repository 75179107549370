@use "../global/" as g;

.p-support{
  &__case{
    padding: 56px 16px 72px;
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    @include g.mq{
      padding: 72px 16px;
    }
    &__list{
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include g.mq{
        gap: 24px 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      li{
        background-color: #F7FAFB;
        text-align: center;
        color: g.$c_blue;
        font-size: 20px;
        font-weight: 500;
        line-height: 32.4px;
        padding: 8px 0;
        border-radius: 4px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @include g.mq{
          font-size: 16px;
          width: 47.8%;
        }
        @include g.mq_m{
          font-size: 18px;
          padding: 15px 0;
        }
        @include g.mq_mm{
          font-size: 20px;
        }
        span{
          display: inline-block;
        }
      }
    }
  }
  &__content{
    padding: 0 16px 72px;
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    &__text{
      margin-bottom: 32px;
      @include g.mq_m{
        display: flex;
        margin-bottom: 40px;
      }
      p:nth-child(1){
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        margin-bottom: 24px;
        position: relative;
        @include g.mq_m{
          padding-bottom: 0;
          margin-bottom: 0;
          margin-right: 32px;
          padding-right: 32px;
        }
        &:before{
          content: "";
          position: absolute;
          width: 240px;
          height: 1px;
          background-color: g.$c_blue;
          right: 0;
          left: 0;
          margin: auto;
          bottom: 0;
          opacity: 0.5;
          @include g.mq_m{
            width: 1px;
            height: 72px;
            left: initial;
            top: 0;
            right: 0;
          }
        }
        span:nth-child(1){
          margin-right: 8px;
          display: inline-block;
        }
        span:nth-child(2){
          font-size: 26px;
          color: g.$c_blue;
          font-weight: 500;
          @include g.mq{
            font-size: 30px;
          }
        }
      }
      p:nth-child(2){
        text-align: center;
        font-size: 16px;
        line-height: 1.8;
        color: g.$c_blue;
        @include g.mq_m{
          text-align: left;
        }
        span{
          display: inline-block;
        }
      }
    }
    &__detail{
      @include g.mq_m{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      &-left{
        background-color: #F7FAFB;
        padding: 30px 16px 40px;
        margin-bottom: 32px;
        @include g.mq{
          padding: 30px 32px 40px;
        }
        @include g.mq_m{
          width: 62%;
        }
        ul{
          li{
            background-color: g.$c_blue;
            padding: 20px 16px;
            color: g.$c_white;
            border-radius: 4px;
            &:not(:last-child){
              margin-bottom: 16px;
            }
            @include g.mq_mm{
              display: flex;
              align-items: center;
              padding: 30px;
            }
            &.is-last{
              @include g.mq_mm{
                padding: 22.5px;
                p:nth-child(1){
                  width: 90%;
                }
              }
            }
            p{
              &:nth-child(1){
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 12px;
                @include g.mq_mm{
                  width: 192px;
                  margin-bottom: 0;
                  margin-right: 8px;
                  line-height: 23px;
                }
                span{
                  display: inline-block;
                }
              }
              &:nth-child(2){
                font-size: 14px;
                line-height: 1.4;
                @include g.mq_mm{
                  flex: 1;
                }
              }
            }
          }
        }
      }
      &-title{
        text-align: center;
        font-size: 24px;
        color: g.$c_blue;
        font-weight: 500;
        letter-spacing: 3px;
        margin-bottom: 28px;
        border-radius: 4px;
      }
      &-right{
        background-color: #F7FAFB;
        padding: 30px 16px 40px;
        border-radius: 4px;
        position: relative;
        @include g.mq{
          padding: 30px 32px 40px;
        }
        @include g.mq_m{
          width: 35%;
        }
        .illust{
          display: none;
          @include g.mq_m{
            display: block;
            position: absolute;
            top: 308px;
            max-width: 100%;
            text-align: center;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        .price{
          background-color: g.$c_blue;
          color: g.$c_white;
          font-size: 30px;
          padding: 48px 0;
          font-weight: 500;
          text-align: center;
          border-radius: 4px;
          span{
            font-size: 46px;
          }
        }
      }
    }
  }
  &__qa{
    padding: 0px 16px 72px;
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    @include g.mq{
      padding: 0 16px 48px;
    }
  }
  &__flow{
    background-color: #F7FAFB;
    padding: 72px 16px 80px;
    &__content{
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      li{
        position: relative;
        @include g.mq_m{
          display: flex;
          align-items: flex-start;
        }
        &:not(:last-child){
          margin-bottom: 40px;
          @include g.mq{
            margin-bottom: 80px;
          }
          &:before{
            content: "";
            position: absolute;
            width: 2px;
            height: calc(100% - 32px);
            bottom: -24px;
            background-color: g.$c_blue;
            left: 32px;
            @include g.mq{
              height: calc(100% + 8px);
              bottom: -64px;
            }
            @include g.mq_m{
              bottom: -72px;
            }
          }
          &:after{
            content: '';
            width: 10px;
            height: 10px;
            border-top: solid 2px g.$c_blue;
            border-right: solid 2px g.$c_blue;
            position: absolute;
            left: 28px;
            bottom: -24px;
            transform: rotate(135deg);
            @include g.mq{
              bottom: -64px;
            }
            @include g.mq_m{
              bottom: -72px;
            }
          }
        }
      }
      &-title{
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        @include g.mq_m{
          margin-bottom: 0;
          width: 473px;
        }
        p{
          &:nth-child(1){
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background-color: g.$c_blue;
            color: g.$c_white;
            font-weight: 500;
            margin-right: 8px;
            span:nth-child(1){
              font-size: 13px;
            }
            span:nth-child(2){
              font-size: 25px;
            }
          }
          &:nth-child(2){
            color: g.$c_blue;
            font-size: 24px;
            font-weight: 500;
          }
        }
      }
      &-text{
        padding-left: 72px;
        font-size: 14px;
        color: g.$c_blue;
        line-height: 25px;
        @include g.mq_m{
          flex: 1;
          padding-left: 0;
          padding-top: 16px;
        }
      }
    }
  }
}