@use "../global/" as g;

.p-company{
  padding: 64px 15px 80px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 128px 16px;
  }
  &__title{
    font-size: 26px;
    font-weight: bold;
    color: g.$c_black;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 1px #F0F1F3;
    @include g.mq_m{
      font-size: 32px;
      margin-bottom: 32px;
    }
  }
  &__message{
    position: relative;
    max-width: 1100px;
    margin: 0 auto 64px;
    @include g.mq{
      margin: 0 auto 128px;
    }
    &__logo{
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
      @include g.mq_m{
        position: absolute;
        right: 100px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 300px;
        display: flex;
        align-items: center;
      }
      img{
        max-width: 328px;
        @include g.mq{
          max-width: 300px;
        }
      }
    }
    &__sub_title{
      font-size: 24px;
      color: g.$c_blue;
      line-height: 1.4;
      font-weight: bold;
      margin-bottom: 40px;
      @include g.mq_m{
        padding-right: 500px;
        font-size: 30px;
      }
      span{
        display: inline-block;
      }
    }
    &__text{
      @include g.mq_m{
        padding-right: 500px;
      }
      p{
        font-size: 16px;
        line-height: 1.8;
        color: g.$c_black;
        &:not(:last-child){
          margin-bottom: 24px;
        }
        &:last-child{
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }
  &__detail{
    position: relative;
    max-width: 1100px;
    margin: 0 auto 32px;
    @include g.mq{
      margin: 0 auto 48px;
    }
    &__table{
      dl{
        border-bottom: solid 1px rgb(102 102 102 /.2);
        padding: 32px 0;
        @include g.mq{
          display: flex;
          padding: 40px 0;
        }
        dt{
          margin-bottom: 20px;
          font-weight: 500;
          @include g.mq{
            margin-bottom: 0;
            width: 250px;
          }
        }
        dd{
          @include g.mq{
            flex: 1;
          }
          span{
            display: inline-block;
            &.is-small{
              font-size: 11px;
              @include g.mq{
                font-size: 16px;
              }
            }
          }
        }
        dt,dd{
          font-size: 16px;
          color: g.$c_black;
          line-height: 1.4;
          @include g.mq{
            line-height: 1.8;
            font-size: 16px;
          }
        }
      }
    }
  }
  &__iframe{
    position:relative;
    width:100%;
    height:0;
    padding-top: 53.1976%;
    @include g.mq{
      padding-top: 40%;
    }
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}