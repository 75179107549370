@use "../global/" as g;

.o-flex{
  max-width: 1132px;
  width: 100%;
  margin: 0 auto;
  @include g.mq_m{
    display: flex;
    justify-content: space-between;
  }
  &__main{
    margin-bottom: 80px;
    @include g.mq_m{
      width: 66.54%;
      margin-bottom: 0;
    }
  }
  &__sidebar{
    width: 100%;
    @include g.mq_m{
      width: 27.27%;
    }
  }
}