@use "../global/" as g;

.p-contact{
  padding: 72px 16px 80px;
  width: 100%;
  max-width: 932px;
  margin: 0 auto;
  @include g.mq{
    padding: 128px 16px;
  }
  &__text{
    color: g.$c_black;
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 40px;
    span{
      display: inline-block;
    }
  }
}

.mw_wp_form .horizontal-item + .horizontal-item{
  margin-left: 0 !important;
}