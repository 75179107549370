@use "../global/" as g;

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+JP:wght@100;200;300;400;500;700;900&display=swap');

html{
  height: -webkit-fill-available;
}

html, body {
  font-family: 'Figtree', sans-serif;
  overflow-x: clip;
}

body{
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  leading-trim: both;
  // &.is-top{
  //   .is-parallax{
  //     &:before{
  //       background-position: center;
  //       background-repeat: no-repeat;
  //       background-size: cover;
  //       content: "";
  //       height: calc(100vh - 60px);
  //       left: 0;
  //       position: fixed;
  //       top: 60px;
  //       width: 100%;
  //       background-image: url(../images/top/body_back_sp.webp);
  //       z-index: -1;
  //       @include g.mq{
  //         background-image: url(../images/top/body_back_pc.webp);
  //       }
  //       @include g.mq_m{
  //         height: calc(100vh - 100px);
  //         top: 100px;
  //       }
  //       .no-webp & {
  //         background-image: url(../images/top/body_back_sp.png);
  //         @include g.mq{
  //           background-image: url(../images/top/body_back_pc.png);
  //         }
  //       }
  //     }
  //   }
  // }
}

.font_sans{
  font-family: 'Noto Sans JP', sans-serif;
}

.font_kozuka{
  font-family: "Kozuka Gothic Pr6N", sans-serif;
}

.font_yugo{
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.font_yumin{
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

.font_hirasan{
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", Meiryo, メイリオ, sans-serif;
}

.font_hiramin {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
}

.font_hirakaku {
  font-family: "HiraKakuProN-W3","ヒラギノ角ゴ ProN W3","ヒラギノ角ゴ Pro W3","HiraKakuPro-W3","HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS Pgothic","Osaka",sans-serif,Helvetica, Helvetica Neue, Arial, Verdana;
}

.font_hel{
  font-family: Helvetica, Helvetica Neue,sans-serif,;
}

.font_meirio{
  font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

header, nav, main, footer, small, button, label {
  display: block;
}

a,button {
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
}

button:hover, button:active{
  outline: 0;
  opacity: 0.8;
  text-decoration: none;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  border-radius: 0;
  outline: none;
}

a:hover, a:active{
  outline: 0;
  opacity: 0.8;
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #a5a5a5;
}
:-ms-input-placeholder {
  color: #a5a5a5;
}
::-moz-placeholder {
  color: #a5a5a5;
}
::-placeholder {
  color: #a5a5a5;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: #f2f2f2;
  border: 1px solid #b5b5b5;
  border-radius: 0;
  outline: none;
}
input[type="checkbox"] {
  cursor: pointer;
}
select {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.containner {
  padding-bottom: 40px;
  @include g.mq {
    padding-bottom: 80px;
  }
  @include g.m(pb_none) {
    padding-bottom: 0;
  }
}


.innerBox {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 5%;
  position: relative;
  @include g.mq{
    padding: 0 2%;
  }
  @include g.mq_mm{
    padding: 0;
  }
}

.min-620{
  min-height: 620px;
}

.mb-30{
  margin-bottom: 30px;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.left{
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.display_to_mq {
  @include g.mq {
    display: none;
  }
}

.display_to_mq_m {
  @include g.mq_m {
    display: none;
  }
}

.display_mq {
  display: none;
  &.is-inline{
    @include g.mq {
      display: inline;
    }
  }
  &.is-block{
    @include g.mq {
      display: block;
    }
  }
}

.display_mq_m {
  display: none;
  &.is-inline{
    @include g.mq_m {
      display: inline;
    }
  }
  &.is-block{
    @include g.mq_m {
      display: block;
    }
  }
}



.relative_wrap {
  position: relative;
}


.inline_block {
  display: inline-block;
}


.text_indent {
  padding-left: 1em;
  text-indent: -1em;
}



.font_mincho {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", "Yu Mincho", "YuMincho", serif;
}

.font_gothic {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, arial, helvetica, sans-serif;
}

.c_pink {
  color: #f29c9f;
}

.post_cnt {
  iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

img.alignleft {
  display: block;
  margin-right: auto;
}

img.alignright {
  display: block;
  margin-left: auto;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}
main{
  // min-height: calc(100vh - 144px);
  position: relative;
  z-index: 1;
}

#page__top{
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  border-radius: 50%;
  overflow: hidden;
  img{
    background-color: g.$c_white;
  }
  @include g.mq_mm(){
    right: 20px;
    bottom: 20px;
  }
}


a[href^="tel:"] {
  @include g.mq{
    pointer-events: none;
  }
}

.is-fixed{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: g.$c_white;
}


.blogcard{
  width: 100%;
  padding: 24px 16px;
  background-color: #F7FAFB;
  margin-bottom: 32px;
  @include g.mq{
    padding: 24px;
    margin-bottom: 40px;
  }
  a{
   @include g.mq{
    display: flex;
    align-items: center;
    color: g.$c_black;
   }
  }
  &_thumbnail{
    width: 100%;
    margin-bottom: 16px;
    @include g.mq{
      width: 200px;
      margin-bottom: 0;
      margin-right: 24px;
    }
    @include g.mq_mm{
      width: 350px;
    }
  }
  &_content{
    flex: 1;
  }
  &_title{
    color: g.$c_black;
    font-weight: bold;
    line-height: 2;
    font-size: 16px;
    @include g.mq_m{
      font-size: 20px;
    }
  }
  &_link{
    color: g.$c_black;
    line-height: 2;
    font-size: 14px;
    @include g.mq_m{
      font-size: 16px;
    }
  }
}

.is-image{
  position: relative;
  .hover{
    overflow:hidden;
    width: 100%;
    height: 100%;
    padding-top: 62.5%;
    position: relative;
    border-radius: 5px;
    a:hover{
      opacity: 1;
    }
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
      transition:1s all;
      @include g.mq_m{
        &:hover{
          transform:scale(1.1,1.1);
          transition:1s all;
        }
      }
    }
  }
}