@use "../global/" as g;

.o-bread_list{
  width: 91.4%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  &.is-single{
    position: relative;
    margin-top: 80px;
    @include g.mq_mm{
      margin-top: 112px;
    }
  }
  li{
    color: g.$c_black;
    font-weight: 400;
    position: relative;
    font-size: 12px;
    &:not(:last-child){
      &:before{
        content:"ｰ";
        position: absolute;
        right: -15px;
      }
    }
    a{
      color: #CBD0D0;
      transform: 0.3s;
      &:hover{
        color: g.$c_black;
      }
    }
  }
}