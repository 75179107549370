@use "../global/" as g;

.p-service_archive{
  padding: 64px 16px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 64px 16px 32px;
  }
  &__title{
    display: flex;
    align-items: center;
    border-bottom: solid 1px #F0F1F3;
    padding-bottom: 24px;
    margin-bottom: 32px;
    &.is-blue{
      color: g.$c_blue;
    }
    &.is-green{
      color: g.$c_green;
    }
    p:nth-child(1){
      margin-right: 16px;
    }
    p:nth-child(2){
      font-size: 26px;
      font-weight: bold;
      line-height: 1;
      @include g.mq{
        font-size: 32px;
      }
    }
  }
  &__text{
    margin-bottom: 48px;
    color: g.$c_black;
    line-height: 1.8;
    @include g.mq{
      margin-bottom: 64px;
    }
  }
  &__content{
    @include g.mq{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &-detail{
      padding-top: 24px;
      position: relative;
      @include g.mq{
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-bottom: 96px;
      }
      @include g.mq_m{
        width: 45.45%;
      }
      &:not(:last-child){
        margin-bottom: 64px;
        @include g.mq{
          margin-bottom: 96px;
        }
      }
      .title{
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #F6F4EC;
        width: 180px;
        height: 83px;;
      }
      .image{
        text-align: center;
        margin-bottom: 24px;
      }
      .text{
        color: g.$c_black;
        font-size: 16px;
        line-height: 1.8;
        margin-bottom: 24px;
      }
      .o-btn{
        margin: auto auto 0 auto;
      }
    }
  }
}