@use "../global/" as g;

.p-top{
  &__features{
    padding: 40px 0 80px;
    overflow: hidden;
    @include g.mq{
      padding: 40px 0 108px;
    }
    &__content{
      position: relative;
      width: 100%;
      max-width: 375px;
      margin: 0 auto 24px;
      height: 336px;
      @include g.mq_m{
        max-width: 1100px;
        height: 496px;
      }
      &-detail{
        width: 216px;
        height: 216px;
        background-size: 100% 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: g.$c_white;
        font-weight: bold;
        @include g.mq_m{
          width: 452px;
          height: 452px;
        }
        p:nth-child(1){
          font-size: 20px;
          margin-bottom: 12px;
          position: relative;
          z-index: 1;
          @include g.mq_m{
            font-size: 32px;
            margin-bottom: 24px;
          }
        }
        p:nth-child(2){
          font-size: 10px;
          position: relative;
          z-index: 1;
          @include g.mq_m{
            font-size: 16px;
          }
        }
        p:nth-child(3){
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          animation: top_circle 5s linear infinite;
        }
        &:nth-child(1){
          bottom: 0;
          left: -34px;
          z-index: 3;
          @include g.mq_m{
            left: 0;
          }
        }
        &:nth-child(2){
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 2;
        }
        &:nth-child(3){
          bottom: 0;
          right: -34px;
          z-index: 1;
          @include g.mq_m{
            right: 0;
          }
        }
      }
    }
  }
  &__service{
    padding-bottom: 80px;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    &__flex{
      @include g.mq{
        display: flex;
      }
    }
    &__content{
      &:not(:last-child){
        margin-bottom: 64px;
      }
      &-detail{
        background-color: g.$c_white;
        border-radius: 8px;
        padding-top: 32px;
        position: relative;
        width: 91.4%;
        margin: 0 auto -64px;
        top: -64px;
        &.is-blue{
          .title{
            p:nth-child(2){
              color: g.$c_blue;
            }
          }
          .list{
            li{
              a{
                &:hover{
                  background-color: g.$c_blue;
                }
              }
              &:last-child {
                width: 100%;
                @include g.mq_mm{
                width: calc(100% - 31.16% - 20px);
                }
              }
            }
          }
        }
        &.is-green{
          .title{
            p:nth-child(2){
              color: g.$c_green;
            }
          }
          .list{
            li{
              width: 100%;
              &:not(:last-child){
                @include g.mq_mm{
                  width: 47.8%;
                }
              }
              a{
                &:hover{
                  background-color: g.$c_green;
                }
              }
            }
          }
        }
        .title{
          margin-bottom: 32px;
          p{
            &:nth-child(1){
              text-align: center;
              margin-bottom: 16px;
            }
            &:nth-child(2){
              font-size: 24px;
              font-weight: bold;
              text-align: center;
              .is-blue & {
                color: g.$c_blue;
              }
              .is-green & {
                color: g.$c_green;
              }
            }
          }
        }
        .list{
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px 0;
          margin-bottom: 32px;
          li{
            width: 47.8%;
            position: relative;
            height: 84px;
            @include g.mq_mm{
              width: 31.46%;
            }
            a{
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: #F6F4EC;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              font-size: 14px;
              line-height: 24px;
              font-weight: 500;
              color: g.$c_black;
              text-align: center;
              transition: 0.3s;
              border-radius: 4px;
              padding: 0 6%;
              &:hover{
                cursor: pointer;
                opacity: 1;
                color: g.$c_white;
              }
            }
          }
        }
      }
    }
  }
  &__flow{
    background-color: #F0F1F3;
    &__circle{
      background-color: g.$c_white;
    }
    &__inner{
      padding: 20px 32px 80px;
      width: 100%;
      max-width: 1164px;
      margin: 0 auto;
      @include g.mq{
        padding: 64px 32px 100px;
      }
    }
  }
  &__interrogative{
    padding: 40px 16px 48px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    @include g.mq{
      padding: 80px 16px 48px;
      display: flex;
      justify-content: space-between;
    }
    p{
      width: 267px;
      @include g.mq{
        width: 31.12%;
      }
      &:nth-child(1){
        margin: 0 0 7px 0;
        @include g.mq{
          margin: 0;
        }
      }
      &:nth-child(2){
        margin: 0 0 8.5px auto;
        @include g.mq{
          margin: 0;
        }
      }
      &:nth-child(3){
        margin: 0 auto;
        @include g.mq{
          margin: 0;
        }
      }
    }
  }
  &__about{
    margin-bottom: 40px;
    @include g.mq{
      margin-bottom: 80px;
    }
    &__top{
      width: 100%;
      max-width: 1100px;
      margin: 0 auto 48px;
      @include g.mq_m{
        margin: 0 auto 80px;
        display: flex;
      }
    }
    &__text{
      padding: 0 16px;
      margin-bottom: 44px;
      @include g.mq_m{
        width: 50%;
        margin-bottom: 0;
      }
      @include g.mq_mm{
        padding: 0;
      }
      &-detail{
        color: g.$c_blue;
        font-size: 16px;
        line-height: 1.8;
        margin-bottom: 32px;
        @include g.mq{
          margin-bottom: 40px;
        }
        span{
          display: inline-block;
        }
        br{
          display: none;
          @include g.mq_m{
            display: block;
          }
        }
      }
      &-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 280px;
        height: 68px;
        border-radius: 60px;
        background-color: g.$c_blue;
        color: g.$c_white;
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        box-shadow: 0 4px 4px rgb(0 0 0 /.25);
        transition: 0.3s;
        border: solid 1px g.$c_blue;
        @include g.mq{
          width: 218px;
          height: 48px;
          margin: 0;
        }
        &:hover{
          background-color: g.$c_white;
          color: g.$c_blue;
          opacity: 1;
        }
      }
    }
    &__image{
      @include g.mq_m{
        flex: 1;
        margin-right: calc(50% - 50vw);
      }
    }
    &__list{
      width: calc(100% - 32px);
      margin: 0 auto;
      max-width: 1140px;
      border-radius: 4px;
      background-color: #F7FEFF;
      padding: 0 10px;
      @include g.mq_m{
        padding: 48px 0;
        display: flex;
        justify-content: center;
      }
      li{
        padding: 24px 0;
        @include g.mq_m{
          padding: 0 24px;
          width: 32%;
        }
        &:not(:last-child){
          border-bottom: solid 1px g.$c_blue;
          @include g.mq_m{
            border-bottom: 0px;
            border-right: solid 1px g.$c_blue;
          }
        }
        .illust{
          width: 100%;
          max-width: 100px;
          margin: 0 auto 24px;
        }
        .title{
          font-size: 20px;
          color: g.$c_blue;
          font-weight: 500;
          margin-bottom: 24px;
          text-align: center;
          line-height: 1.4;
          @include g.mq_m{
            font-size: 18px;
            line-height: 1;
          }
          span{
            background:linear-gradient(transparent 70%, #FFDBDC 70%);
          }
        }
        .text{
          font-size: 14px;
          line-height: 1.4;
          color: g.$c_blue;
          text-align: center;
          span{
            display: inline-block;
          }
        }
      }
    }
    &__illust{
      width: 100%;
      max-width: 1305px;
      margin: 0 auto;
    }
  }
  &__support{
    width: 100%;
    max-width: 1186px;
    margin: 0 auto;
    padding: 60px 16px 80px;
    @include g.mq{
      padding: 80px 16px 96px;
    }
  }
}
