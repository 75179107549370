@use "../global/" as g;

.p-lp{
  &__title{
    margin-bottom: 32px;
    @include g.mq{
      margin-bottom: 56px;
    }
    &.is-bg_white{
      h2{
        span:nth-child(2){
          color: #F0F1F3;
        }
      }
    }
    &.is-bg_blue{
      h2{
        span:nth-child(2){
          color:g.$c_white;
        }
      }
    }
    h2{
      font-size: 24px;
      font-weight: bold;
      line-height: 1.4;
      text-align: center;
      position: relative;
      @include g.mq{
        font-size: 32px;
      }
      span:nth-child(1){
        position: relative;
        z-index: 2;
      }
      span:nth-child(2){
        position: absolute;
        width: 100%;
        top: -35px;
        left: 0;
        font-size: 72px;
        text-align: center;
        z-index: 1;
        opacity: 0.6;
        @include g.mq{
          font-size: 100px;
          top: -48px;
        }
      }
    }
    p{
      margin-top: 32px;
      color: g.$c_black;
      text-align: center;
      font-size: 16px;
      line-height: 1.8;
      span{
        display: inline-block;
      }
    }
  }
  &__bg{
    background-color: rgb(82 192 216 /.2);
    padding: 104px 0 80px;
    @include g.mq{
      padding: 96px 0 128px;
    }
  }
  &__feature{
    padding-bottom: 56px;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    @include g.mq{
      padding-bottom: 80px;
    }
    &__content{
      padding: 0 16px;
    }
    &__detail{
      background-color: g.$c_white;
      border-radius: g.$c_white;
      width: 100%;
      max-width: 1233px;
      border-radius: 8px;
      padding: 64px 16px;
      @include g.mq{
        width: 90%;
        display: flex;
        align-items: center;
        padding: 35px 16px;
      }
      @include g.mq_mm{
        padding: 35px;
      }
      @include g.mq_design{
        padding: 35px 132px;
      }
      &:not(:last-child){
        margin: 0 0 40px;
      }
      &:nth-child(odd){
        @include g.mq{
          margin: 0 0 40px auto;
          flex-direction: row-reverse;
          .p-lp__feature__detail-text{
            margin-right: 0;
            margin-left: 40px;
          }
        }
        @include g.mq_mm{
          .p-lp__feature__detail-text{
            margin-left: 132px;
          }
        }
      }
      &-text{
        margin-bottom: 24px;
        @include g.mq{
          flex: 1;
          margin-right: 48px;
        }
        @include g.mq_mm{
          margin-right: 132px;
        }
      }
      &-image{
        text-align: center;
        @include g.mq{
          width: 292px;
        }
      }
      &-title{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding-bottom: 8px;
        margin-bottom: 32px;
        border-bottom: solid 1px #F0F1F3;
        p:nth-child(1){
          color: g.$c_blue;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-right: 8px;
          span:nth-child(1){
            font-size: 13px;
          }
          span:nth-child(2){
            font-size: 62px;
          }
        }
        p:nth-child(2){
          font-size: 24px;
          color: g.$c_black;
          font-weight: bold;
          padding-bottom: 8px;
          @include g.mq_m{
            font-size: 28px;
          }
        }
      }
      &-discription{
        font-size: 16px;
        color: g.$c_black;
        line-height: 1.8;
      }
    }
  }
  &__problem{
    padding: 56px 16px 100px;
    width: 100%;
    max-width: 944px;
    margin: 0 auto;
    position: relative;
    @include g.mq{
      padding: 72px 16px 100px;
    }
    &:before{
      content: "";
      position: absolute;
      width: 66.16px;
      height: 22.37px;
      background-image: url(../images/dental/problem_yajirushi.svg);
      background-size: 100% 100%;
      right: 0;
      left: 0;
      margin: auto;
      bottom: 40px;
    }
    &__list{
      width: 100%;
      @include g.mq{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 32px 0;
      }
      li{
        background-color: #F6F4EC;
        padding: 24px 32px;
        display: flex;
        align-items: center;
        @include g.mq{
          width: 48.24%;
        }
        @include g.mq_m{
          padding: 24px 40px;
        }
        &:not(:last-child){
          margin-bottom: 16px;
          @include g.mq{
            margin-bottom: 0;
          }
        }
        p:nth-child(1){
          width: 30px;
          margin-right: 16px;
        }
        p:nth-child(2){
          flex: 1;
          font-size: 16px;
          line-height: 1.8;
          color: g.$c_black;
        }
      }
    }
  }
  &__solution{
    padding: 0 16px 80px;
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    &__title{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      p{
        &:nth-child(1){
          margin-right: 10px;
        }
        &:nth-child(2){
          font-size: 24px;
          color: g.$c_black;
          font-weight: bold;
          padding-top: 8px;
        }
      }
    }
    &__text{
      text-align: center;
      margin-bottom: 40px;
      color: g.$c_black;
      line-height: 1.8;
      span{
        display: inline-block;
      }
      @include g.mq{
        margin-bottom: 56px;
      }
    }
    &__list{
      width: 100%;
      max-width: 172px;
      margin: 0 auto;
      @include g.mq{
        max-width: 580px;
        display: flex;
        justify-content: space-between;
      }
      li{
        @include g.mq{
          width: 172px;
        }
        &:not(:last-child){
          margin-bottom: 32px;
          @include g.mq{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__service{
    padding: 0 16px 40px;
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    @include g.mq{
      padding: 0 16px 80px;
    }
    &__list{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 8px 0;
      margin-bottom: 32px;
      @include g.mq{
        gap: 24px 0;
      }
      li{
        width: 47.8%;
        min-height: 83px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        background-color: g.$c_white;
        border-radius: 4px;
        @include g.mq_m{
          width: 22.81%;
        }
      }
      &.half {
        li {
        @include g.mq_m{
          width: 48.81%;
          padding: 0 2%;
        }
      }
      }
    }
    &__text{
      text-align: center;
      color: g.$c_black;
      line-height: 1.8;
      font-size: 16px;
      span{
        display: inline-block;
      }
    }
  }
  &__plan{
    width: 100%;
    padding: 0 16px;
    max-width: 1132px;
    margin: 0 auto;
    &__content{
      @include g.mq{
        display: flex;
        justify-content: space-between;
      }
      &-detail{
        border-radius: 2px;
        @include g.mq{
          width: 48.5%;
        }
        &:not(:last-child){
          margin-bottom: 32px;
          @include g.mq{
            margin-bottom: 0;
          }
        }
        &.is-blue{
          background-color: g.$c_blue;
          border: solid 8px g.$c_blue;
        }
        &.is-green{
          background-color: #52C0D8;
          border: solid 8px #52C0D8;
        }
      }
      &-title{
        text-align: center;
        color: g.$c_white;
        padding: 8px 0 16px;
        font-size: 24px;
        font-weight: bold;
      }
      &-text{
        background-color: g.$c_white;
        padding: 32px 8px;
        @include g.mq{
          padding: 32px 16px;
        }
        @include g.mq_m{
          padding: 32px  16px;
        }
        p{
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: solid 1px #E5E6E9;
          text-align: center;
          color: g.$c_black;
          font-weight: bold;
          font-size: 24px;
        }
        ul{
          li{
            font-size: 16px;
            line-height: 1.8;
            color: g.$c_black;
            position: relative;
            padding-left: 24px;
            &:before{
              content: '';
              display: block;
              position: absolute;
              top: 10px;
              left: 2px;
              width: 14px;
              height: 7px;
              border-left: 2px solid #44C194;
              border-bottom: 2px solid #44C194;
              transform: rotate(-45deg);
            }
            &:not(:last-child){
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    &__plan-detail {
      margin-top: 24px;
      background: #f9f9f9;
      line-height: 1.8;
      padding: 16px;
    }
  }
  &__flow{
    width: 100%;
    padding: 112px 16px 144px;
    max-width: 1132px;
    margin: 0 auto;
    background-color: g.$c_white;
    &__list{
      @include g.mq{
        display: flex;
        flex-wrap: wrap;
      }
      li{
        &:not(:last-child){
          margin-bottom: 36px;
          position: relative;
          @include g.mq{
            margin-bottom: 0;
            &:before{
              content: "";
              position: absolute;
              width: 130px;
              height: 11px;
              background-image: url(../images/dental/lp_yajirushi.svg);
              background-size: 100% 100%;
              top: 40px;
              left: 80%;
            }
          }
          @include g.mq_m{
            &:before{
              width: 261px;
              height: 22px;
              left: 65%;
              top: 33px;
            }
          }
          @include g.mq_mm{
            &:before{
              left: 70%;;
            }
          }
        }
        &:not(:nth-child(3n)){
          @include g.mq{
            margin-right: 2%;
          }
          @include g.mq_m{
            margin-right: 5%;
          }
        }
        @include g.mq{
          width: 32%;
        }
        @include g.mq_m{
          width: 30%;
        }
      }
      &-logo{
        text-align: center;
        margin-bottom: 16px;
      }
      &-detail{
        background-color: #F6F4EC;
        border-radius: 2px;
        padding: 24px 16px;
        p:nth-child(1){
          color: g.$c_black;
          font-weight: bold;
          font-size: 20px;
          text-align: center;
          border-bottom: solid 1px #E5E6E9;
          margin-bottom: 24px;
          padding-bottom: 24px;
        }
        p:nth-child(2){
          color: g.$c_black;
          font-size: 16px;
          line-height: 1.8;
        }
      }
    }
  }
  &__qa{
    padding: 72px 16px 80px;
    width: 100%;
    max-width: 1132px;
    margin: 0 auto;
    @include g.mq{
      padding: 72px 16px 128px;
    }
    &__content{
      dl{
        background-color: #F6F4EC;
        padding: 32px 12px;
        @include g.mq{
          padding: 32px;
        }
        &:not(:last-child){
          margin-bottom: 32px;
        }
        dt{
          padding-bottom: 16px;
          margin-bottom: 32px;
          border-bottom: solid 1px g.$c_black;
          display: flex;
          align-items: center;
          span:nth-child(1){
            display: inline-block;
            width: 40px;
            font-size: 36px;
            color: g.$c_l_blue;
            font-weight: 500;
            font-family: 'Noto Sans JP', sans-serif;
          }
          span:nth-child(2){
            font-size: 18px;
            font-weight: bold;
            line-height: 1.9;
            flex: 1;
            @include g.mq{
              font-size: 20px;
              line-height: 1.4;
            }
          }
        }
        dd{
          font-size: 16px;
          line-height: 1.8;
          color: g.$c_black;
          @include g.mq{
            padding-left: 40px;
          }
        }
      }
    }
  }
  .o-flow {
    &__circle {
      background-color: #DCF2F7;
    }
  }
}