@use "../global/" as g;

.o-support_list{
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px 0;
  @include g.mq{
    flex-direction: row;
    gap: 32px 0;
  }
  &.is-top{
    @include g.mq{
      gap: 56px 0;
    }
  }
  &.is-page{
    margin-bottom: 40px;
    @include g.mq{
      flex-direction: column;
    }
    @include g.mq_m{
      flex-direction: row;
    }
  }
  &__content{
    background-color: #F6F4EC;
    border-radius: 6px;
    padding: 16px;
    width: 100%;
    @include g.mq{
      width: 48.9%;
    }
    .is-page & {
      @include g.mq{
        width: 100%;
      }
      @include g.mq_m{
        width: 48.9%;
      }
    }
    &:nth-child(odd){
      @include g.mq{
        margin-right: 2.2%;
      }
      .is-page & {
        @include g.mq{
          margin-right: 0;
        }
        @include g.mq_m{
          margin-right: 2.2%;
        }
      }
    }
    .is-top & {
      @include g.mq{
        width: 31%;
      }
      &:nth-child(odd){
        @include g.mq{
          margin-right: 0%;
        }
      }
      &:not(:nth-child(3n)){
        @include g.mq{
          margin-right: 3.5%;
        }
      }
    }
    &__category{
      margin-bottom: 8px;
      span{
        display: inline-block;
        font-size: 12px;
        line-height: 2;
        color: g.$c_white;
        background-color: #52C0D8;
        padding: 0 12px;
        border-radius: 2px;
        font-weight: bold;
      }
    }
    &__title{
      font-size: 16px;
      color: g.$c_black;
      font-weight: 500;
      line-height: 1.6;
      margin-bottom: 16px;
      @include g.mq{
        font-size: 12px;
      }
      @include g.mq_m{
        font-size: 14px;
      }
      .p-service__content__detail & {
        font-size: 16px;
        margin-bottom: 16px;
        @include g.mq{
          font-size: 12px;
        }
        @include g.mq{
          font-size: 14px;
        }
      }
    }
    &__bg{
      background-color: g.$c_white;
      padding: 16px;
      &-content{
        .p-service__content__detail & {
          li{
            padding-left: 0;
            &:before{
              content: none;
            }
            p{
              margin-bottom: 0;
            }
          }
        }
        li{
          &:not(:last-child){
            margin-bottom: 8px;
          }
          display: flex;
          p:nth-child(1){
            width: 110px;
            height: 26px;
            border-radius: 40px;
            color: g.$c_white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: g.$c_blue;
            margin-right: 8px;
            font-size: 12px;
            .is-top & {
              @include g.mq{
                width: 72px;
                font-size: 10px;
              }
              @include g.mq_m{
                width: 110px;
                font-size: 12px;
              }
            }
          }
          p:nth-child(2){
            flex: 1;
            color: g.$c_black;
            font-size: 14px;
            line-height: 1.8;
          }
        }
      }
    }
  }
}