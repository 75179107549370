@use "../global/" as g;

.p-archive{
  padding: 64px 16px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 128px 16px;
  }
  &__list{
    &__row{
      padding: 32px 32px 32px 16px;
      display: flex;
      color: g.$c_black;
      border-bottom: solid 1px #F0F1F3;
      background-color: g.$c_white;
      transition: 0.3s;
      position: relative;
      &:before{
        content: '';
        width: 10px;
        height: 10px;
        border-top: solid 2px g.$c_black;
        border-right: solid 2px g.$c_black;
        position: absolute;
        right: 10px;
        top: 0px;
        bottom: 0;
        margin: auto;
        transform: rotate(45deg);
        transition: 0.3s;
        @include g.mq{
          right: 20px;
        }
      }
      &:hover{
        opacity: 1;
        @include g.mq{
          background-color: g.$c_blue;
          color: g.$c_white;
          &:before{
            right: 12px;
            border-top: solid 2px g.$c_white;
            border-right: solid 2px g.$c_white;
          }
        }
      }
      @include g.mq{
        padding: 40px 40px 40px 16px;
      }
      &:first-child{
        border-top: solid 1px #F0F1F3;
      }
      &-date{
        width: 88px;
        margin-right: 16px;
        line-height: 1.6;
        font-size: 16px;
        @include g.mq{
          font-size: 20px;
          width: 112px;
        }
      }
      &-title{
        flex: 1;
        font-size: 16px;
        line-height: 1.6;
        @include g.mq{
          font-size: 20px;
        }
      }
    }
  }
}