@use "../global/" as g;

.o-qa{
  dl{
    background-color: rgb(248 251 251 /.8);
    width: 100%;
    padding: 24px 16px 32px;
    border-radius: 4px;
    @include g.mq{
      padding: 24px 32px;
    }
    &:not(:last-child){
      margin-bottom: 24px;
    }
    dt{
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      span:nth-child(1){
        width: 40px;
        height: 40px;
        color: g.$c_white;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: g.$c_blue;
        border-radius: 50%;
        padding-bottom: 2px;
      }
      span:nth-child(2){
        flex: 1;
        font-size: 18px;
        color: g.$c_blue;
        font-weight: 500;
        line-height: 1.4;
        margin-left: 16px;
      }
    }
    dd{
      display: flex;
      align-items: center;
      span:nth-child(1){
        width: 40px;
        height: 40px;
        color: g.$c_white;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FF8A8B;
        border-radius: 50%;
        padding-bottom: 2px;
      }
      span:nth-child(2){
        flex: 1;
        font-size: 14px;
        color: g.$c_blue;
        line-height: 1.4;
        margin-left: 16px;
      }
    }
  }
}