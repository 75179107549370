@use "../global/" as g;

.l-footer{
  background-color: g.$c_d_blue;
  padding: 64px 16px 140px;
  @include g.mq{
    padding: 64px 16px 32px;
  }
  &__inner{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 24px;
    @include g.mq{
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin: 0 auto 40px;
    }
  }
  &__text{
    @include g.mq{
      width: 270px;
      margin-right: 24px;
    }
    &__logo{
      margin-bottom: 24px;
    }
    &__detail{
      color: g.$c_white;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 24px;
    }
    &__sns{
      display: flex;
      li{
        &:not(:last-child){
          margin-right: 9px;
        }
      }
    }
  }
  &__list{
    margin-bottom: 56px;
    width: 100%;
    @include g.mq{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    ul{
      &:not(:last-child){
        margin-bottom: 32px;
        @include g.mq{
          margin-right: 32px;
        }
        @include g.mq_mm{
          margin-bottom: 0;
        }
      }
      li{
        &:not(:last-child){
          margin-bottom: 32px;
        }
        a,span{
          color: g.$c_white;
          font-size: 16px;
          text-decoration: underline;
        }
      }
      .parent{
        a{
          position: relative;
          padding-left: 16px;
          margin-bottom: 8px;
          display: inline-block;
          &:before{
            content: "・";
            position: absolute;
            left: 0;
          }
        }
        .child{
          li{
            line-height: 2;
            margin-bottom: 0;
            a{
              font-size: 12px;
              position: relative;
              padding-left: 24px;
              margin-bottom: 0;
              text-decoration: none;
              &:before{
                content: "-";
                top: 0px;
                left: 8px;
              }
            }
          }
        }
      }
    }
  }
  &__copyright{
    text-align: center;
    color: g.$c_white;
    padding-top: 20px;
    border-top: solid 1px rgb(60 121 190 /.3);
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
  }
}