@use "../global/" as g;

.l-header{
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 70px;
  background-color: g.$c_white;
  top: 0;
  left: 0;
  z-index: 10;
  transition: 0.3s;
  box-shadow: 0 4px 4px rgb(0 0 0 /.06);
  @include g.mq_mm(){
    height: 100px;
    overflow: visible;
  }
  &.is-open{
    height: 100vh;
    height: -webkit-fill-available;
    .innerBox{
      align-items: flex-start;
    }
    .l-header__logo{
      padding-top: 13px;
    }
  }
  .innerBox{
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 5%;
    @include g.mq_mm{
      padding: 0 0 0 12px;
    }
    @include g.mq_l{
      padding: 0 0 0 24px;
    }
  }
  &__logo{
    width: 101px;
    z-index: 3;
    @include g.mq_mm{
      margin-right: 40px;
      width: 134px;
    }
  }
  &__ham{
    width: 70px;
    height: 70px;
    position: relative;
    margin: 0 0 0 auto;
    z-index: 3;
    background-color: g.$c_blue;
    @include g.mq_mm{
      display: none;
    }
    span{
      width: 26px;
      height: 2px;
      background-color: g.$c_white;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transition-duration:0.8s;
      border-radius: 5px;
      &:nth-child(1){
        top: 24px;
      }
      &:nth-child(2){
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:nth-child(3){
        bottom: 24px;
      }
    }
  }
  &__list{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top: 0px;
    left: 0;
    z-index: 2;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 108px 15px 24px;
    transition: 0.8s;
    &.is-open{
      display: block;
      background-color: rgb(255 255 255 /.9);
    }
    &::-webkit-scrollbar{
      display: none;
    }
    @include g.mq_mm{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      height: 100%;
      top: inherit;
      padding: 0;
      margin: 0;
      border-top: 0px;
      flex: 1;
      overflow: visible;
    }
    ul{
      margin-bottom: 48px;
      @include g.mq_mm{
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
      li{
       &:not(:last-child){
        margin-bottom: 24px;
        @include g.mq_mm{
          margin-bottom: 0;
          margin-right: 24px;
        }
        @include g.mq_l{
          margin-right: 28px;
        }
        @include g.mq_design{
          margin-right: 40px;
        }
       }
       .select{
        select{
          padding: 12px 18px;
          background-color: g.$c_white;
          border: solid 1px g.$c_black;
          border-radius: 40px;
        }
       }
        a,span{
          color: g.$c_black;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.8;
          @include g.mq_mm{
            font-size: 12px;
            line-height: 1;
          }
          @include g.mq_l{
            font-size: 14px;
          }
        }
        a{
          @include g.mq_mm{
            &:hover{
              opacity: 1;
              cursor: pointer;
              position: relative;
              &:after{
                content: '';
                position: absolute;
                left: 0;
                bottom: -5px;
                height: 1px;
                background-color: g.$c_black;
                animation: border_anim 0.3s linear forwards;
              }
            }
          }
        }
        span{
          @include g.mq_mm{
            &:hover{
              cursor: pointer;
            }
          }
        }
        &.parent{
          position: relative;
          &.is-open{
            .parent_name{
              &:before{
                top: 6px;
                transform: rotate(-45deg);
              }
            }
            .child{
              display: block;
            }
          }
          .parent_name{
            display: inline-block;
            margin-bottom: 8px;
            position: relative;
            @include g.mq_mm{
              margin-bottom: 0;
            }
            &:before{
              content: '';
              width: 8px;
              height:8px;
              border-top: solid 1px g.$c_blue;
              border-right: solid 1px g.$c_blue;
              position: absolute;
              right: -20px;
              transform: rotate(135deg);
              top: 8px;
              transition: 0.3s;
              @include g.mq_mm{
                border-top: solid 1px g.$c_black;
                border-right: solid 1px g.$c_black;
                top: 1px;
                right: -14px;
              }
            }
          }
          .child{
            margin-left: 16px;
            display: none;
            @include g.mq_mm{
              position: absolute;
              top: 48px;
              left: 0;
              background-color: rgb(255 255 255 /0.9);
              width: 340px;
              height: 136px;
              margin: 0;
              padding: 24px 36px 20px;
            }
            @include g.mq_l{
              width: 380px;
            }
            ul{
              @include g.mq_mm{
                display: flex;
                justify-content: space-between;
                margin-bottom: 32px;
              }
            }
            li{
              margin-bottom: 0;
              &:not(:last-child){
                margin-bottom: 8px;
                @include g.mq_mm{
                  margin-bottom: 0;
                  margin-right: 0;
                  position: relative;
                  &:before{
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 40px;
                    background-color: g.$c_blue;
                    right: -28px;
                    top: -10px;
                  }
                }
              }
            }
            .close{
              display: none;
              @include g.mq_mm{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 247px;
                height: 44px;
                background-color: g.$c_blue;
                margin: 0 auto;
                color: g.$c_white;
                font-size: 14px;
                font-weight: bold;
                span{
                  position: relative;
                  padding-left: 20px;
                  color: g.$c_white;
                  &:before,&:after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    width: 3px;
                    height: 12px;
                    background: g.$c_white;
                  }
                  &:before{
                    transform: translate(-50%,-50%) rotate(45deg);
                  }
                  &:after{
                    transform: translate(-50%,-50%) rotate(-45deg);
                  }
                }
              }
            }
          }
        }
        &.tel{
          display: block;
          margin-right: 32px;
          &:hover{
            a{
              &:before{
                content: none !important;
              }
            }
          }
        }
        &.is-sp{
          @include g.mq_mm{
            display: none;
          }
        }
        &.contact{
          display: none;
          @include g.mq_mm{
            width: 160px;
            height: 100px;
            color: g.$c_white;
            background-color: g.$c_blue;
            border: solid 2px g.$c_blue;
            font-size: 14px;
            font-weight: bold;
            transition: 0.3s;
            position: relative;
            display: block;
            &:hover{
              background-color: g.$c_white;
              a{
                color: g.$c_blue;
              }
            }
            a{
              color: g.$c_white;
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s;
              &:after{
                content: none;
              }
            }
            &.is-top{
              height: 160px;
              a{
                padding-top: 28px;
              }
            }
          }
        }
      }
    }
    .cta{
      background-color: rgb(255 255 255 /0.9);
      border-radius: 4px;
      padding: 10px 16px 16px;
      width: 100%;
      max-width: 312px;
      margin: 0 auto;
      @include g.mq_mm{
        display: none;
      }
      .title{
        text-align: center;
        font-size: 18px;
        color: g.$c_blue;
        margin-bottom: 24px;
        font-weight: 500;
        span{
          background:linear-gradient(transparent 70%, #FFDBDC 70%);
        }
      }
      .day{
        font-size: 14px;
        color: g.$c_blue;
        margin-bottom: 8px;
        text-align: center;
      }
      .tel{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: g.$c_white;
        border-radius: 34px;
        padding: 19px 0;
        border: solid 1px g.$c_blue;
        color: g.$c_blue;
        font-weight: 500;
        margin-bottom: 16px;
        span{
          &:nth-child(1){
            font-size: 16px;
            display: inline-block;
            margin-right: 8px;
          }
          &:nth-child(2){
            font-size: 28px;
          }
        }
      }
      .contact{
        border-radius: 34px;
        padding: 19px 0;
        background-color: #FF8A8B;
        display: flex;
        align-items: center;
        justify-content: center;
        color: g.$c_white;
        box-shadow: 0 4px 4px rgb(0 0 0 /.25);
        span:nth-child(1){
          display: inline-block;
          margin-right: 11px;
        }
        span:nth-child(2){
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}


.active_line1{
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration:1s;
  top: 33px !important;
  border-radius: 3px;
  background-color: g.$c_white !important;
}

.active_line2{
  opacity: 0;
}

.active_line3{
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 24px !important;
  right: -1px;
  border-radius: 3px;
  background-color: g.$c_white !important;
}