@use "../global/" as g;

@keyframes show {
	0% {
		transform:translate(-100%,0);
		opacity:0;
	}
	100% {
		transform:translate(-50%,0);
    opacity: 1;
	}
}

@keyframes top_title {
	0% {
		top: 0px;
	}
	50% {
		top: 10px;
	}
	100% {
		top: 0px;
	}
}

@keyframes top_worries_content {
	0% {
		bottom: -25px;
		@include g.mq{
			bottom: -50px;
		}
	}
	50% {
		bottom: -35px;
		@include g.mq{
			bottom: -90px;
		}
	}
	100% {
		bottom: -25px;
		@include g.mq{
			bottom: -50px;
		}
	}
}

@keyframes show_text {
	0% {
		transform:translate(-100%,0);
		opacity:0;
	}
	100% {
		transform:translate(0,0);
    opacity: 1;
	}
}

@keyframes show_text_right {
	0% {
		transform:translate(100%,0);
		opacity:0;
	}
	100% {
		transform:translate(0,0);
    opacity: 1;
	}
}

@keyframes load {
	0% {
		top: 0;
	}
	100% {
		top: -100%;
    visibility: hidden;
	}
}

@keyframes border_anim {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}

@keyframes ham_open {
	0%{
		top: 100%;
	}
	100%{
		top: 0;
	}
}

@keyframes ham_close {
	0%{
		top: 0;
	}
	100%{
		top: -120%;
	}
}

@keyframes ham_opacity {
	0%{
		opacity: 0;
	}
	75%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@keyframes UpAnime{
  from {
		transform: translateY(188px);
  }
  to {
		transform: translateY(0px);
  }
}

@keyframes DownAnime{
  from {
		transform: translateY(0);
  }
  to {
		transform: translateY(108px);
  }
}

@keyframes DownOpacity{
  from {
		opacity: 0;
  }
  to {
		opacity: 1;
  }
}

@keyframes UpOpacity{
  from {
		opacity: 1;
  }
  to {
		opacity: 0;
  }
}

@keyframes bgroop_sp {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -700px 0;
	}
}

@keyframes bgroop_pc {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -1400px 0;
	}
}

@keyframes bou {
	0% {
			-webkit-clip-path: inset(0 0 100% 0);
			clip-path: inset(0 0 100% 0);
	}
	25% {
			-webkit-clip-path: inset(0 0 0 0);
			clip-path: inset(0 0 0 0);
	}
	75% {
			-webkit-clip-path: inset(0 0 0 0);
			clip-path: inset(0 0 0 0);
	}
	100% {
			-webkit-clip-path: inset(100% 0 0 0);
			clip-path: inset(100% 0 0 0);
	}
}

@keyframes btn_hover {
	from {
		width: 0px;
	}
	to {
		width: calc(100% - 10px);
	}
}

@keyframes zoom_image {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.7;
		transform:scale(1.05,1.05);
	}
	100% {
		opacity: 1;
		transform:scale(1.0,1.0);
	}
}

@keyframes image_opacity_left {
	0% {
		opacity: 0;
		left: -100%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes image_opacity_right {
	0% {
		opacity: 0;
		left: 100%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes hover_circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadein {
  opacity: 0;
}
.fadein_first {
  opacity: 0;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.updown {
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
}
.downup {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.slide-right {
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
}
.slide-left {
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
}
.leftin {
  opacity: 0;
}
.leftInto {
  -webkit-animation-name: leftInto;
  animation-name: leftInto;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.left01 {
  @include g.mq{
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
}
.left02 {
  @include g.mq{
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
}
.left03 {
  @include g.mq{
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
}
.left04 {
  @include g.mq{
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
}
.left05 {
  @include g.mq{
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
}
.left06 {
  @include g.mq{
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@-webkit-keyframes leftInto {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes leftInto {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom_back {
	0% {
		 transform: scale(1);
	}
	50% {
		 transform: scale(1.25);
	}
	100% {
		transform: scale(1);
 }
}

@keyframes zoom_back_lower {
	0% {
		 transform: scale(1);
	}
	50% {
		 transform: scale(1.25);
	}
	100% {
		transform: scale(1);
 }
}


@keyframes zoom_image_top {
	0% {
		transform: scale(1);
		left: 0;
	}
	50% {
			transform: scale(1.2);
			left: 100px;
	}
	100% {
		transform: scale(1);
		left: 0;
	}
}

@keyframes mv_zoom {
	0% {
		 transform: scale(1);
	}
	50% {
		transform: scale(1.04);
	}
	100% {
		transform: scale(1);
	}
}

.fade_group div ,
.fade_group2 div {
    content: '';
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100%;
    pointer-events: none;
}
.fade_group div {
    z-index: 99999;
    transition: .6s;
}
.fade_group2 div {
    z-index: 99998;
    transition: .8s;
}
.fade_group div:nth-of-type(1) {
    background: rgba(255,255,255,.3);
}
.fade_group div:nth-of-type(2) {
    background: rgba(255,255,255,.5);
    transition-delay:.1s;
}
.fade_group div:nth-of-type(3) {
    background: rgba(255,255,255,.7);
    transition-delay:.2s;
}
.fade_group div:nth-of-type(4) {
    background: rgba(255,255,255,1);
    transition-delay:.3s;
}
.fade_group2 div:nth-of-type(1) {
    background: rgba(255,255,255,.8);
    transition-delay: .2s;
}
.fade_group2 div:nth-of-type(2) {
    background: rgba(255,255,255,.8);
    transition-delay: .3s;
}
body.o-page_link_loading .fade_group div{
    left: 0;
}
body.o-page_link_loading .fade_group2 div{
    left: 100vw;
}

.fade{
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	z-index: 9000;
	background-color: g.$c_white;
}

.fade p{
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: none;
	z-index: 9999;
	width: 225px;
	height: 47px;
}


@keyframes top_circle {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes top_mv {
	0% {
		opacity: 0;
		right: -100px;
	}
	50% {
		opacity: 0.3;
		right: -50px;
	}
	100% {
		opacity: 1;
		right: 0;
	}
}