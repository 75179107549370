@use "../global/" as g;

.p-wechat{
  padding: 72px 16px 80px;
  width: 100%;
  max-width: 932px;
  margin: 0 auto;
  @include g.mq{
    padding: 128px 16px;
  }
  &__image{
    width: 100%;
    max-width: 430px;
    margin: 0 auto 40px;
    @include g.mq{
      margin: 0 auto 64px;
    }
  }
  &__list{
    &__title{
      font-size: 20px;
      color: g.$c_black;
      font-weight: 500;
      text-align: center;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 32px;
      }
    }
    ul{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 24px;
      @include g.mq{
        gap: 32px;
        justify-content: center;
      }
      li{
        font-size: 16px;
        position: relative;
        &:not(:last-child){
          &:before{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            right: -12px;
            background-color: g.$c_blue;
            @include g.mq{
              right: -16px;
            }
          }
        }
        @include g.mq{
          font-size: 20px;
        }
        a{
          color: g.$c_blue;
        }
      }
    }
  }
}