@use "../global/" as g;

.o-flow{
  background-color: #F0F1F3;
  position: relative;
  &__circle{
    background-color: g.$c_white;
  }
  &__inner{
    padding: 20px 32px 80px;
    width: 100%;
    max-width: 1164px;
    margin: 0 auto;
    @include g.mq{
      padding: 64px 32px 100px;
    }
  }
  &__step{
    width: 100%;
    // max-width: 188px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 56px;
    @include g.mq{
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    li{
      background-color: g.$c_white;
      border-radius: 4px;
      height: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @include g.mq{
        width: 17.09%;
      }
      &:not(:last-child){
        margin-bottom: 56px;
        @include g.mq{
          margin-bottom: 0;
        }
        &:before{
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          margin: auto;
          bottom: -32px;
          background-image: url(../images/common/flow.svg);
          background-size: 100% 100%;
          width: 14px;
          height: 18px;
          @include g.mq{
            left: initial;
            top: 0;
            bottom: 0;
            right: -20px;
            transform: rotate(-90deg);
          }
          @include g.mq_m{
            right: -24px;
          }
          @include g.mq_mm{
            right: -28px;
          }
        }
      }
      p{
        &:nth-child(1){
          width: 100%;
          text-align: center;
          position: absolute;
          top: -12px;
          left: 0;
          font-size: 20px;
          font-weight: bold;
          color: g.$c_blue;
        }
        &:nth-child(2){
          font-size: 18px;
          line-height: 1.5;
          color: g.$c_black;
          text-align: center;
          @include g.mq{
            font-size: 14px;
          }
          @include g.mq_m{
            font-size: 18px;
          }
        }
      }
    }
  }
  &__content{
    background-color: g.$c_white;
    padding: 32px 16px;
    margin-bottom: 32px;
    &.is-last{
      margin-bottom: 0;
    }
    @include g.mq{
      padding: 36px 40px;
      display: flex;
    }
    &__title{
      font-size: 18px;
      margin-bottom: 40px;
      color: g.$c_black;
      @include g.mq{
        width: 290px;
        margin-bottom: 0;
        padding-top: 4px;
      }
      span{
        color: #52C0D8;
        font-weight: bold;;
      }
    }
    &__detail{
      font-size: 16px;
      line-height: 1.6;
      color: g.$c_black;
      @include g.mq{
        flex: 1;
      }
    }
  }
}