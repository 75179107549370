@use "../global/" as g;

.o-title {
  &.is-top{
    margin-bottom: 40px;
    p:nth-child(1){
      display: block;
      text-align: center;
      font-size: 18px;
      color: #52C0D8;
      font-weight: bold;
      margin-bottom: 16px;
    }
    p:nth-child(2){
      font-size: 26px;
      text-align: center;
      line-height: 1.4;
      font-weight: bold;
      color: g.$c_black;
      span{
        display: inline-block;;
      }
    }
  }
  &.is-cross{
    margin-bottom: 32px;
    p{
      padding-top: 56px;
      position: relative;
      font-size: 28px;
      color: g.$c_blue;
      line-height: 1.6;
      font-weight: 500;
      display: inline-block;
      letter-spacing: 3px;
      @include g.mq{
        font-size: 36px;
      }
      span{
        position: absolute;
        font-size: 20px;
        left: 27px;
        top: 24px;
        letter-spacing: 0;
        @include g.mq_m{
          top: 16px;
        }
        &:before{
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          background-image: url(../images/common/cross_blue.svg);
          top: -27px;
          left: -30px;
        }
      }
    }
  }
  &.is-center{
    text-align: center;
  }
  &.is-white{
    p{
      color: g.$c_white;
      span{
        &:before{
          background-image: url(../images/common/cross.svg);
        }
      }
    }
  }
  &.is-lower{
    text-align: center;
    font-size: 26px;
    line-height: 1.4;
    color: g.$c_blue;
    font-weight: 500;
    margin-bottom: 44px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: 72px;
      height: 4px;
      border-radius: 5px;
      background-color: g.$c_blue;
      right: 0;
      left: 0;
      margin: auto;
      bottom: -16px;
    }
    @include g.mq{
      margin-bottom: 64px;
      font-size: 30px;
    }
    span{
      display: inline-block;
    }
  }
}