@use "../global/" as g;

.p-lower{
  position: relative;
  &__bg{
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100vw;
      height: 100%;
      background-image: url(../images/lower/lower_bg_sp.webp);
      background-position: top right;
      background-repeat: no-repeat;
      animation: zoom_back_lower 16s infinite;
      @include g.mq{
        background-image: url(../images/lower/lower_bg_pc.webp);
      }
      .no-webp & {
        background-image: url(../images/lower/lower_bg_sp.png);
        @include g.mq{
          background-image: url(../images/lower/lower_bg_pc.png);
        }
      }
    }
  }
  &__content{
    position: relative;
    &__sp_list{
      width: 90%;
      margin: 0 auto 48px;
      background-color: #F1FAFD;
      padding: 32px 16px;
      @include g.mq{
        display: none;
      }
      p{
        font-size: 16px;
        color: g.$c_blue;
        font-weight: bold;
        margin-bottom: 40px;
        line-height: 1.2;
        span{
          font-size: 21px;
        }
      }
      nav{
        ul{
          li{
            font-size: 14px;
            padding: 15px 15px 15px 36px;
            position: relative;
            line-height: 1.2;
            &:before{
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 4px 0 4px 6px;
              border-color: transparent transparent transparent g.$c_text;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 4px;
            }
            &.is-active{
              background-color: g.$c_white;
              &:before{
                border-color: transparent transparent transparent g.$c_blue;
              }
              a{
                color: g.$c_blue;
              }
            }
            a{
              color: g.$c_text;
              font-weight: bold;;
            }
          }
        }
      }
    }
  }
  &__text{
    text-align: center;
    font-size: 16px;
    color: g.$c_text;
    line-height: 1.8;
    letter-spacing: 0.018em;
    margin-bottom: 64px;
    @include g.mq{
      font-size: 18px;
      margin-bottom: 80px;
    }
    &.is-mb160{
      margin-bottom: 80px;
      @include g.mq{
        margin-bottom: 160px;
      }
    }
    &.is-mb48{
      margin-bottom: 32px;
      @include g.mq{
        margin-bottom: 48px;
      }
    }
    span{
      display: inline-block;
    }
  }
}