@use "../global/" as g;

.p-service{
  padding: 64px 16px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 64px 16px 72px;
  }
  &__text{
    margin-bottom: 80px;
    font-size: 16px;
    color: g.$c_black;
    line-height: 1.8;
    span{
      @include g.mq_mm{
        display: inline-block;
      }
    }
  }
  &__content{
    @include g.mq{
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
    }
    &:not(:last-child){
      margin-bottom: 80px;
      @include g.mq{
        margin-bottom: 0;
        padding: 40px 0 80px;
      }
    }
    &__title{
      padding-bottom: 8px;
      margin-bottom: 24px;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      line-height: 1.4;
      span{
        display: inline-block;
      }
      @include g.mq{
        width: 300px;
        margin-right: 24px;
      }
      @include g.mq_m{
        width: 344px;
        font-size: 24px;
      }
      &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #52C0D8;
        bottom: 0;
        left: 0;
        @include g.mq{
          bottom: initial;
          top: -40px;
        }
      }
    }
    &__detail{
      position: relative;
      @include g.mq{
        flex: 1;
        &:before{
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #F0F1F3;
          top: -40px;
          left: 0;
        }
      }
      ol{
        .is-ol_li{
          padding: 24px 0;
          @include g.mq{
            padding: 48px 0;
          }
          &:first-child{
            padding: 0 0 24px;
            @include g.mq{
              padding: 0 0 48px;
            }
          }
          &:last-child{
            padding: 24px 0 0;
            @include g.mq{
              padding: 48px 0 0;
            }
          }
          &:not(:last-child){
            border-bottom: solid 2px #F0F1F3;
          }
        }
      }
      h3{
        font-size: 22px;
        margin-bottom: 24px;
        font-weight: bold;
        color: g.$c_black;
        span:nth-child(1){
          color: g.$c_blue;
          display: inline-block;
          margin-right: 16px;
        }
      }
      p{
        font-size: 16px;
        color: g.$c_black;
        line-height: 1.8;
        margin-bottom: 32px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      ul{
        margin-bottom: 32px;
        &:last-child{
          margin-bottom: 0;
        }
        &.is-bg{
          background-color: #F6F4EC;
          padding: 20px 16px;
          display: flex;
          flex-wrap: wrap;
          gap: 4px 12px;
          justify-content: flex-start;
          @include g.mq{
            display: flex;
            flex-wrap: wrap;
            gap: 8px 20px;
            justify-content: flex-start;
          }
          li{
            &:before{
              content: "・";
            }
          }
        }
        li{
          color: g.$c_black;
          position: relative;
          font-size: 16px;
          line-height: 1.8;
          padding-left: 1em;
          &:before{
            content: "◾";
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}