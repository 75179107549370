@use "../global/" as g;

.p-privacy{
  padding: 64px 16px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 108px 16px 120px;
  }
  h2{
    padding-bottom: 24px;
    margin-bottom: 32px;
    border-bottom: solid 1px #F0F1F3;
    color: g.$c_black;
    line-height: 1;
    font-size: 26px;
    font-weight: bold;
    @include g.mq{
      font-size: 32px;
    }
  }
  p{
    font-size: 16px;
    line-height: 1.8;
    color: g.$c_black;
    &:not(:last-child){
      margin-bottom: 32px;
    }
  }
  ol{
    padding-left:20px;
    &:not(:last-child){
      margin-bottom: 32px;
    }
    li{
      font-size: 16px;
      line-height: 1.8;
      list-style: decimal;
      color: g.$c_black;
    }
  }
}