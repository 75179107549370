/*
 * 色の設定
 */

$c_white: #ffffff;
$c_black: #333;
$c_blue: #3C79BE;
$c_d_blue: #2B4460;
$c_l_blue: #008BCC;
$c_text: #000000;
$c_green: #44C194;


//ブレイクポイント
$breakpoint: 768px;
$breakpoint_m: 1024px;
$breakpoint_mm: 1180px;
$breakpoint_l: 1280px;
$breakpoint_design: 1366px;
$breakpoint_ll: 1500px;
$breakpoint_max: 1920px;