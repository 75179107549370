@use "../global/" as g;

.l-sidebar{
  &__title{
    font-size: 20px;
    color: g.$c_blue;
    font-weight: bold;
    line-height: 38px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    border-bottom: solid 1px rgb(102 102 102 /.2);
  }
  &__category{
    li{
      border-bottom: solid 1px rgb(102 102 102 /.2);
      padding-bottom: 8px;
      font-size: 14px;
      line-height: 1.8;
      color: g.$c_black;
      &:not(:last-child){
        margin-bottom: 12px;
      }
      a{
        color: g.$c_black;
      }
    }
  }
}