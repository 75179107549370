@use "../global/" as g;

.p-single{
  padding: 80px 16px 64px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 80px 16px 112px;
  }
  &__date{
    font-size: 16px;
    color: g.$c_black;
    margin-bottom: 24px;

  }
  &__title{
    font-size: 26px;
    line-height: 1.4;
    color: g.$c_black;
    padding-bottom: 16px;
    margin-bottom: 32px;
    border-bottom: solid 1px #F0F1F3;
    font-weight: bold;
    @include g.mq{
      margin-bottom: 40px;
      font-size: 32px;
    }
  }
  &__content{
    margin-bottom: 40px;
    @include g.mq{
      margin-bottom: 56px;
    }
    p{
      font-size: 16px;
      line-height: 1.8;
      color: g.$c_black;
      &:not(:last-child){
        margin-bottom: 32px;
      }
    }
  }
}