@use "../global/" as g;

.o-fixed{
  background-color: g.$c_white;
  width: 100%;
  height: 108px;
  position: fixed;
  bottom: -128px;
  left: 0;
  z-index: 1;
  padding: 24px 12px;
  &.UpMove{
    animation: UpAnime 0.5s forwards;
  }
  &.DownMove{
    animation: DownAnime 0.5s forwards;
  }
  @include g.mq{
    display: none !important;
  }
  &__title{
    text-align: center;
    color: g.$c_blue;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 16px;
  }
  &__inner{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 352px;
    margin: 0 auto;
    a{
      width: 48.86%;
      font-size: 14px;
    }
  }
}