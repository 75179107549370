@use "../global/" as g;

.p-support_archive{
  padding: 80px 16px 64px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 80px 16px 112px;
  }
  &__title{
    font-size: 26px;
    color: g.$c_black;
    line-height: 1.4;
    padding-bottom: 16px;
    margin-bottom: 32px;
    border-bottom: solid 1px #F0F1F3;
    font-weight: bold;
    @include g.mq_m{
      font-size: 32px;
      margin-bottom: 40px;
    }
  }
}