@use "../global/" as g;

.o-mv{
  position: relative;
  margin-top: 70px;
  @include g.mq_mm{
    margin-top: 100px;
  }
  &.is-top{
    width: 100%;
    padding: 24px 0 64px;
    background-color: g.$c_white;
    overflow: hidden;
    @include g.mq_m{
      padding: 160px 0 84px;
    }
    @include g.mq_mm{
      padding: 220px 0 84px;
    }
    .bg_text{
      font-size: 201px;
      color: #F0F1F3;
      position: absolute;
      z-index: 0;
      text-align: center;
      width: 100%;
      opacity: 0.6;
      font-weight: bold;
      top: 25%;
      @include g.mq{
        font-size: 540px;
        top: 28%;
      }
      @include g.mq_m{
        text-align: left;
        top: initial;
        bottom: -200px;
      }
    }
    .inner{
      width: 100%;
      max-width: 1366px;
      margin: 0 auto;
      position: relative;
      @include g.mq_m{
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
      }
    }
    .image{
      width: 95.4%;
      margin: 0 0 8px auto;
      position: relative;
      animation: top_mv 0.5s linear forwards;
      @include g.mq_m{
        position: absolute;
        bottom: 0;
        width: 44.5%;
      }
      img{
        width: 100%;
      }
    }
    .text{
      padding: 0 16px;
      position: relative;
      @include g.mq_m{
        padding: 0 40% 0 16px;
        width: 100%;
      }
      @include g.mq_l{
        padding: 0 40% 0 32px;
      }
      @include g.mq_design{
        padding: 0 40% 0 140px;
      }
      .title{
        font-size: 40px;
        color: g.$c_black;
        letter-spacing: 5%;
        line-height: 1.4;
        font-weight: bold;
        margin-bottom: 32px;
        @include g.mq_mm{
          font-size: 60px;
          margin-bottom: 56px;
        }
        span{
          display: inline-block;
        }
      }
      .discription{
        font-size: 16px;
        line-height: 1.8;
        color: g.$c_black;
        margin-bottom: 32px;
        @include g.mq_mm{
          font-size: 18px;
          margin-bottom: 56px;
        }
        span{
          @include g.mq_m{
            display: block;
          }
        }
      }
      .btn{
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        gap: 24px;
        display: flex;
        flex-direction: column;
        @include g.mq{
          flex-direction: row;
          justify-content: space-between;
          max-width: 684px;
        }
        @include g.mq_m{
          margin: 0;
        }
      }
    }
  }
  &.is-lower{
    width: 91.4%;
    height: 240px;
    // max-width: 1300px;
    margin: 96px auto 16px;
    padding: 0 16px 16px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    @include g.mq{
      margin: 100px auto 16px;
      padding: 0 32px 24px;
    }
    h1{
      color: g.$c_white;
      font-weight: bold;
      p{
        &:nth-child(1){
          font-size: 18px;
          line-height: 1;
          margin-bottom: 8px;
        }
        &:nth-child(2){
          font-size: 32px;
          line-height: 1.4;
          @include g.mq{
            font-size: 40px;
          }
        }
      }
    }
  }
  &.is-about{
    background-image: url(../images/about/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/about/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/about/mv_sp.png);
      @include g.mq{
        background-image: url(../images/about/mv_pc.png);
      }
    }
  }
  &.is-company{
    background-image: url(../images/company_info/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/company_info/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/company_info/mv_sp.png);
      @include g.mq{
        background-image: url(../images/company_info/mv_pc.png);
      }
    }
  }
  &.is-contact{
    background-image: url(../images/contact/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/contact/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/contact/mv_sp.png);
      @include g.mq{
        background-image: url(../images/contact/mv_pc.png);
      }
    }
  }
  &.is-service-japan{
    background-image: url(../images/service-japan/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/service-japan/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/service-japan/mv_sp.png);
      @include g.mq{
        background-image: url(../images/service-japan/mv_pc.png);
      }
    }
  }
  .is-service-global{
    background-image: url(../images/service-global/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/service-global/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/service-global/mv_sp.png);
      @include g.mq{
        background-image: url(../images/service-global/mv_pc.png);
      }
    }
  }
  &.is-tax{
    background-image: url(../images/tax/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/tax/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/tax/mv_sp.png);
      @include g.mq{
        background-image: url(../images/tax/mv_pc.png);
      }
    }
  }
  &.is-assets{
    background-image: url(../images/assets/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/assets/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/assets/mv_sp.png);
      @include g.mq{
        background-image: url(../images/assets/mv_pc.png);
      }
    }
  }
  &.is-invoice{
    background-image: url(../images/invoice/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/invoice/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/invoice/mv_sp.png);
      @include g.mq{
        background-image: url(../images/invoice/mv_pc.png);
      }
    }
  }
  &.is-estate{
    background-image: url(../images/estate/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/estate/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/estate/mv_sp.png);
      @include g.mq{
        background-image: url(../images/estate/mv_pc.png);
      }
    }
  }
  &.is-totalsupport{
    background-image: url(../images/totalsupport/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/totalsupport/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/totalsupport/mv_sp.png);
      @include g.mq{
        background-image: url(../images/totalsupport/mv_pc.png);
      }
    }
  }
  &.is-tax_agent{
    background-image: url(../images/tax_agent/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/tax_agent/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/tax_agent/mv_sp.png);
      @include g.mq{
        background-image: url(../images/tax_agent/mv_pc.png);
      }
    }
  }
  &.is-support{
    background-image: url(../images/support/mv_sp.webp);
    @include g.mq{
      background-image: url(../images/support/mv_pc.webp);
    }
    .no-webp & {
      background-image: url(../images/support/mv_sp.png);
      @include g.mq{
        background-image: url(../images/support/mv_pc.png);
      }
    }
  }
  &.is-lp{
    padding: 48px 16px 64px;
    background-color: g.$c_white;
    width: 100%;
    max-width: 1332px;
    margin: 70px auto 0;
    @include g.mq{
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }
    @include g.mq_mm{
      margin: 100px auto 0;
    }
    .image{
      text-align: center;
      margin-bottom: 28px;
      @include g.mq{
        width: 48%;
      }
      @include g.mq_design{
        width: 608px;
      }
    }
    .text{
      @include g.mq{
        width: 48%;
      }
      @include g.mq_design{
        width: auto;
        flex: 1;
        margin-right: 30px;
        padding-left: 100px;
      }
      h1{
        font-size: 40px;
        color: g.$c_black;
        line-height: 1.25;
        font-weight: bold;
        margin-bottom: 24px;
        white-space: nowrap;
        @include g.mq_m{
          font-size: 50px;
          margin-bottom: 24px;
        }
        @include g.mq_mm{
          font-size: 60px;
        }
      }
      p{
        color: g.$c_black;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.8;
      }
    }
  }
}