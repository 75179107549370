@use "../global/" as g;

.o-form{
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  font-family: 'Noto Sans JP', sans-serif;
  &__row{
    padding: 36px 0;
    border-bottom: dotted 1px #D9D9D9;
    @include g.mq{
      display: flex;
      align-items: center;
    }
    &.is-start{
      @include g.mq{
        align-items: flex-start;
      }
    }
    &__title{
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      @include g.mq{
        width: 230px;
        margin-bottom: 0;
        position: relative;
        font-size: 18px;
        display: block;
      }
      .is-start & {
        @include g.mq{
          padding-top: 12px;
          span{
            top: 12px;
          }
        }
      }
      span{
        background-color: #FF7F00;
        font-size: 10px;
        color: g.$c_white;
        font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 12.5px;
        margin-left: 8px;
        @include g.mq{
          position: absolute;
          width: 40px;
          height: 20px;
          color: g.$c_white;
          top: 0px;
          bottom: 0;
          right: 20px;
          margin: auto;
          font-size: 12px;
        }
      }
    }
    &__content{
      @include g.mq{
        flex: 1;
      }
      .mwform-radio-field{
        display: block;
        @include g.mq{
          display: inline-block;
        }
        &:not(:last-child){
          margin-bottom: 16px;
          @include g.mq{
            margin-bottom: 0;
            margin-right: 16px;
          }
        }
        label{
          position: relative;
          &:hover{
            cursor: pointer;
          }
          input[type="radio"]{
            display: none;
          }
          span{
            position: relative;
            padding-left: 32px;
            display: inline-block;
            &:before{
              content: "";
              position: absolute;
              width: 24px;
              height: 24px;
              left: 0px;
              top: 0;
              bottom: 0;
              margin: auto;
              border-radius: 50%;
              border: solid 3px #A8A5A5;
            }
            &:after{
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              left: 6px;
              top: 0;
              bottom: 0;
              margin: auto;
              border-radius: 50%;
              background-color: #A8A5A5;
              opacity: 0;
            }
          }
          input[type="radio"]:checked + span:after{
            opacity: 1;
          }
        }
      }
      .error{
        display: inline-block;
        margin-top: 8px;
      }
      input[type="text"],input[type="email"]{
        width: 100%;
        background-color: #F9F9F9;
        border: solid 1px #BCBCBC;
        border-radius: 5px;
        height: 60px;
        padding: 0 8px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
        color: g.$c_black;
        @include g.mq{
          padding: 0 16px;
          font-size: 16px;
        }
      }
      textarea{
        resize: vertical;
        width: 100%;
        background-color: #F9F9F9;
        border: solid 1px #BCBCBC;
        border-radius: 5px;
        height: 160px;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
        color: g.$c_black;
      }
    }
  }
  &__submit{
    width: 300px;
    height: 64px;
    margin: 0 auto;
    position: relative;
    border-radius: 64px;
    overflow: hidden;
    @include g.mq{
      width: 264px;
      height: 56px;
    }
    input[type="submit"]{
      background-color: g.$c_blue;
      border: solid 1px g.$c_blue;
      border-radius: 64px;
      font-size: 14px;
      font-weight: bold;
      color: g.$c_white;
      width: 100%;
      height: 100%;
      transition: 0.3s;
      &:hover{
        cursor: pointer;
        background-color: g.$c_white;
        color: g.$c_blue;
      }
    }
  }
  &__privacy{
    margin: 40px auto 32px;
    text-align: center;
    color: g.$c_black;
    a{
      color: g.$c_black;
      text-decoration: underline;
    }
    label{
      &:hover{
        cursor: pointer;
      }
      input[type="checkbox"]{
        display: none;
      }
      span{
        position: relative;
        padding-left: 24px;
        &:before{
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 4px;
          border: solid 1px #B3B3B3;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          top:8px;
          left: 4px;
          width: 10px;
          height: 5px;
          border-left: 2px solid #B3B3B3;
          border-bottom: 2px solid #B3B3B3;
          transform: rotate(-45deg);
          opacity: 0;
        }
      }
      input[type="checkbox"]:checked + span:after{
        opacity: 1;
      }
    }
  }
}