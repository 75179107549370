@use "../global/" as g;

.o-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 320px;
  height: 60px;
  border-radius: 40px;
  color: g.$c_white;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  &:hover{
    opacity: 1;
    &:before{
      background-color: g.$c_white;
    }
    &:after{
      border-radius: 0%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 6px;
      background-color: transparent;
    }
  }
  &:before{
    content: "";
    position: absolute;
    background-color: rgb(255 255 255 /.4);
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 16px;
    width: 32px;
    height: 32px;
    transition: 0.3s;
  }
  &:after{
    content: "";
    position: absolute;
    background-color: g.$c_white;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 28px;
    width: 8px;
    height: 8px;
    transition: 0.3s;
  }
  &.is-sp-center{
    margin: 0 auto;
    @include g.mq{
      margin: 0;
    }
  }
  &.is-center{
    margin: 0 auto;
  }
  &.is-blue{
    background-color: g.$c_blue;
    &:hover{
      &:after{
        border-color: transparent transparent transparent g.$c_blue;
      }
    }
  }
  &.is-green{
    background-color: g.$c_green;
    &:hover{
      &:after{
        border-color: transparent transparent transparent g.$c_green;
      }
    }
  }
  &.is-white{
    background-color: g.$c_white;
    color: g.$c_blue;
    &:hover{
      &:before{
        background-color: g.$c_blue;
      }
      &:after{
        border-color: transparent transparent transparent g.$c_white;
      }
    }
    &:before{
      background-color: rgb(60 121 190 /.4);
    }
    &:after{
      background-color: g.$c_blue;
    }
  }
}
