@use "../global/" as g;

.p-support_single{
  padding: 80px 16px 64px;
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  @include g.mq{
    padding: 80px 16px 112px;
  }
  &__category{
    margin-bottom: 24px;
    span{
      display: inline-block;
      font-size: 12px;
      line-height: 2;
      color: g.$c_white;
      background-color: #52C0D8;
      padding: 0 12px;
      border-radius: 2px;
      font-weight: bold;
    }
  }
  &__title{
    font-size: 26px;
    line-height: 1.4;
    padding-bottom: 16px;
    margin-bottom: 32px;
    border-bottom: solid 1px #F0F1F3;
    font-weight: bold;
    @include g.mq{
      font-size: 32px;
      margin-bottom: 40px;
    }
  }
  &__list{
    margin-bottom: 32px;
    @include g.mq{
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    li{
      &:not(:last-child){
        margin-bottom: 16px;
        @include g.mq{
          margin-bottom: 0;
        }
      }
      &:not(:nth-last-child(-n+2)){
        @include g.mq{
          margin-bottom: 16px;
        }
      }
      @include g.mq{
        width: 48%;
      }
      display: flex;
      p:nth-child(1){
        width: 110px;
        height: 26px;
        border-radius: 40px;
        color: g.$c_white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: g.$c_blue;
        margin-right: 8px;
        font-size: 12px;
        .is-top & {
          @include g.mq{
            width: 72px;
            font-size: 10px;
          }
          @include g.mq_m{
            width: 110px;
            font-size: 12px;
          }
        }
      }
      p:nth-child(2){
        flex: 1;
        color: g.$c_black;
        font-size: 14px;
        line-height: 1.8;
      }
    }
  }
  &__detail{
    background-color: #F6F4EC;
    padding: 32px 16px 24px;
    margin-bottom: 32px;
    @include g.mq{
      padding: 32px 32px 24px;
      margin-bottom: 40px;
    }
    &__title{
      color: g.$c_black;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 8px;
      margin-bottom: 24px;
      border-bottom: solid 1px g.$c_black;
      line-height: 1.4;
    }
    &__content{
      li{
        padding-left: 1em;
        position: relative;
        color: g.$c_black;
        line-height: 1.8;
        font-size: 16px;
        &:before{
          content: "・";
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  &__content{
    &:not(:last-child){
      margin-bottom: 32px;
      @include g.mq{
        margin-bottom: 56px;
      }
    }
    &__title{
      color: g.$c_black;
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 8px;
      margin-bottom: 24px;
      border-bottom: solid 1px #F0F1F3;
      line-height: 1.4;
    }
    &__text{
      color: g.$c_black;
      line-height: 1.6;
    }
  }
}