@use "../global/" as g;

.o-cta{
  padding: 48px 16px;
  width: 100%;
  background-image: url(../images/common/bg_cta_sp.webp);
  background-size: cover;
  background-position: top right;
  .no-webp & {
    background-image: url(../images/common/bg_cta_sp.png);
  }
  @include g.mq{
    background-image: url(../images/common/bg_cta_pc.webp);
    .no-webp & {
      background-image: url(../images/common/bg_cta_pc.png);
    }
  }
  &.is-lp{
    background-image: none;
    background-color: g.$c_blue;
    padding: 32px 16px;
  }
  &__inner{
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }
  &__title{
    margin-bottom: 40px;
    p{
      text-align: center;
      color: g.$c_white;
      font-weight: bold;
      &:nth-child(1){
        margin-bottom: 14px;
        font-size: 18px;
      }
      &:nth-child(2){
        font-size: 26px;
        @include  g.mq{
          font-size: 32px;
        }
      }
    }
  }
  &__text{
    color: g.$c_white;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 32px;
    @include g.mq{
      text-align: center;
    }
  }
  &__btn{
    width: 100%;
    max-width: 320px;
    margin: 0 auto 32px;
    &.is-mb0{
      margin: 0 auto;
    }
    @include g.mq{
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      max-width: 588px;
    }
    a{
      &:nth-child(2){
        display: inline-block;
        margin-top: 32px;
        @include g.mq{
          width: 235px;
          margin-top: 0;
        }
      }
    }
  }
  &__contact{
    background-color: g.$c_white;
    border-radius: 12px;
    padding: 24px 24px 32px;
    @include g.mq_mm{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 48px 24px;
    }
    &__title{
      text-align: center;
      font-size: 16px;
      line-height: 1.6;
      color: g.$c_black;
      margin-bottom: 24px;
      @include g.mq_mm{
        margin-bottom: 0;
        width: 209px;
        text-align: right;
        span{
          display: block;
        }
      }
    }
    &__btn{
      width: 100%;
      max-width: 256px;
      margin: 0 auto;
      @include g.mq{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 816px;
      }
      li{
        @include g.mq{
          width: 31.37%;
        }
        &:not(:last-child){
          margin-bottom: 24px;
          @include g.mq{
            margin-bottom: 0;
          }
        }
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 60px;
          width: 100%;
          border: solid 2px g.$c_blue;
          border-radius: 40px;
          text-align: center;
          font-size: 16px;
          color: g.$c_blue;
          font-weight: bold;
          @include g.mq{
            font-size: 14px;
          }
          @include g.mq_m{
            font-size: 16px;
          }
          &:before{
            content: "";
            position: absolute;
            background-color: rgb(60 121 190 /.4);
            border-radius: 50%;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 16px;
            width: 32px;
            height: 32px;
            transition: 0.3s;
          }
          &:after{
            content: "";
            position: absolute;
            background-color: g.$c_blue;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 28px;
            width: 8px;
            height: 8px;
            transition: 0.3s;
          }
          &:hover{
            opacity: 1;
            &:before{
              background-color: g.$c_blue;
            }
            &:after{
              border-color: transparent transparent transparent g.$c_white;
              border-radius: 0%;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 4px 0 4px 6px;
              background-color: transparent;
            }
          }
          p{
            &:nth-child(1){
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 24px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}