@use "../global/" as g;

.p-about{
  padding-top: 48px;
  @include g.mq{
    padding-top: 72px;
  }
  &__feature{
    width: 100%;
    max-width: 1332px;
    margin: 0 auto 64px;
    padding: 0 16px;
    @include g.mq{
      margin: 0 auto 128px;
    }
    @include g.mq_m{
      margin: 0 auto 168px;
    }
    &__detail{
      width: 100%;
      max-width: 1218px;
      @include g.mq_m{
        display: flex;
        align-items: center;
        gap: 0 5.5%;
        justify-content: flex-end;
      }
      &:not(:last-child){
        margin-bottom: 56px;
        @include g.mq{
          margin-bottom: 64px;
        }
      }
      &:nth-child(odd){
        flex-direction: row-reverse;
      }
      &:nth-child(even){
        @include g.mq_m{
          margin: 0 0 64px auto;
        }
        .p-about__feature__detail-text{
          @include g.mq_m{
            max-width: 408px;
          }
          .number{
            @include g.mq_m{
              right: initial;
              left: -202px;
            }
          }
        }
      }
      &-text{
        padding-left: 24px;
        position: relative;
        margin-bottom: 32px;
        @include g.mq_m{
          margin-bottom: 0;
          width: 47.8%;
        }
          &:before{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: #F0F1F3;
            top: 0;
            left: 0;
          }
          &:after{
            content: "";
            position: absolute;
            width: 2px;
            height: 30px;
            background-color: #52C0D8;
            left: 0;
            top: -5px;
          }
        .sub_title{
          color: #52C0D8;
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 16px;
          position: relative;
        }
        .number{
          position: absolute;
          right: -8px;
          top: -32px;
          font-size: 140px;
          color: #F0F1F3;
          opacity: 0.6;
          font-weight: bold;
          @include g.mq_m{
            font-size: 196px;
            right: -40px;
            top: -64px;
          }
          @include g.mq_l{
            right: -8px;
          }
        }
        .title{
          font-size: 26px;
          line-height: 1.4;
          color: g.$c_black;
          margin-bottom: 32px;
          font-weight: bold;
          position: relative;
          @include g.mq{
            font-size: 32px;
          }
        }
        .text{
          color: g.$c_black;
          line-height: 1.6;
          span{
            @include g.mq{
              display: block;
            }
          }
        }
      }
      &-image{
        @include g.mq_m{
          width: 42.3%;
        }
      }
    }
  }
  &__title{
    margin-bottom: 48px;
    @include g.mq{
      margin-bottom: 40px;
    }
    p{
      text-align: center;
      font-weight: bold;
      &:nth-child(1){
        color: #52C0D8;
        margin-bottom: 14px;
        font-size: 18px;
      }
      &:nth-child(2){
        color: g.$c_black;
        font-size: 26px;
        @include g.mq{
          font-size: 32px;
        }
      }
    }
  }
  .o-flow{
    &__circle{
      background-color: #52C0D8;
    }
  }
  &__content{
    position: relative;
    padding-bottom: 64px;
    @include g.mq{
      padding-bottom: 128px;
    }
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(../images/about/ballon.webp);
      background-position: top center;
      top: 105px;
      left: 0;
      .no-webp & {
        background-image: url(../images/about/ballon.png);
      }
    }
    &__illust{
      width: 100%;
      max-width: 332px;
      margin: 0 auto 48px;
      position: relative;
      @include g.mq{
        margin: 0 auto 64px;
        max-width: 1092px;
        display: flex;
        justify-content: space-between;
      }
      li{
        @include g.mq{
          width: 30.4%;
        }
        &:not(:last-child){
          margin-bottom: 40px;
          @include g.mq{
            margin-bottom: 0;
          }
        }
        p{
          &:nth-child(1){
            margin-bottom: 8px;
          }
          &:nth-child(2){
            text-align: center;
            color: g.$c_white;
            font-weight: bold;
            font-size: 22px;
            line-height: 1.6;
            @include g.mq{
              font-size: 14px;
            }
            @include g.mq_m{
              font-size: 20px;
            }
            @include g.mq_mm{
              font-size: 22px;
            }
          }
        }
      }
    }
    &__discription{
      text-align: center;
      font-size: 24px;
      color: g.$c_white;
      position: relative;
      font-weight: bold;
      margin-bottom: 24px;
      line-height: 1.4;
      @include g.mq_m{
        font-size: 32px;
        margin-bottom: 48px;
      }
      .inline{
        display: inline-block;
      }
      .c_blue{
        color: g.$c_blue;
        font-size: 32px;
        @include g.mq_m{
          font-size: 40px;
        }
        .inline{
          position: relative;
          &:before{
            content: "";
            position: absolute;
            width: 100%;
            bottom: -0px;
            left: 0;
            border-bottom: dashed 2px g.$c_white;
            @include g.mq_m{
              bottom: -8px;
            }
          }
        }
      }
    }
    &__text{
      position: relative;
      text-align: center;
      padding: 0 5%;
      p{
        color: g.$c_white;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        &:not(:last-child){
          margin-bottom: 24px;
        }
        span{
          display: inline-block;
        }
      }
    }
  }
}